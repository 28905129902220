@layer components {
  .btn-primary {
    @apply py-2 bg-primary-500 hover:bg-primary-400 rounded-lg text-sm text-center text-white font-medium;
  }
  .btn-secondary {
    @apply py-2 bg-white hover:bg-gray-100 hover:text-primary-700 rounded-lg text-sm text-gray-900 font-medium border border-gray-200 md:w-auto dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700
    /* focus:outline-none  focus:z-10 focus:ring-2 focus:ring-gray-200 */;
  }
  .btn-danger {
    @apply text-sm font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-900;
  }
  .btn-primary-disabled {
    @apply disabled:cursor-not-allowed disabled:bg-primary-300;
  }

  .input-err-msg {
    @apply mt-1 text-xs text-red-600;
  }
  .input {
    @apply block w-full rounded-lg border-0 py-2 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-500 focus-visible:outline-none sm:text-sm sm:leading-6;
  }
  .input-invalid {
    @apply border-red-500 ring-red-500 focus:border-red-500 focus:ring-red-500;
  }
  .input-label {
    @apply block text-sm font-medium text-gray-700 leading-6;
  }
  .input-disabled {
    @apply bg-gray-100 text-gray-500 cursor-not-allowed ring-gray-300 focus:ring-gray-300;
  }
  .input-checkbox {
    @apply w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 cursor-pointer dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600;
  }

  .menu {
    @apply inline-flex px-2 py-3 mr-2 bg-white border rounded-lg divide-y divide-gray-100 shadow-md dark:bg-gray-700 dark:divide-gray-600;
  }
  .menu-item {
    @apply flex items-center w-full text-sm text-gray-700 py-2 pl-1.5 rounded-md hover:bg-gray-100 hover:text-primary-600 dark:hover:bg-gray-600 dark:hover:text-white;
  }
  .menu-item-text {
    @apply font-medium ml-2 mr-6;
  }

  .dialog-btn-container {
    @apply p-6 bg-white flex flex-col sm:flex-row-reverse rounded-b-lg gap-1.5 sm:gap-0;
  }
  .dialog-sub-header {
    @apply text-lg font-medium leading-6 text-gray-800 capitalize select-none cursor-default;
  }
}
